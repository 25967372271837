'use client';

import Image from 'next/image';
import bg from '@/public/banner_desktop.jpg';
import LinkComponent from '@/src/components/ui/LinkComponent';

const Header = () => {
    return (
        <header className="mt-20 overflow-hidden md:mt-0 md:-mx-15">
            <div className="relative rounded-xl overflow-hidden md:scale-105">
                <LinkComponent
                    href="/"
                    className="absolute w-2/10 h-3/5 left-3/100 top-2/10 opacity-0"
                >
                    Black Friday
                </LinkComponent>
                <div className="w-full relative"></div>
                <Image src={bg} alt="Picture" priority />
            </div>
        </header>
    );
};

export default Header;
